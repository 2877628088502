/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "~highlight.js/styles/googlecode.css";
@import "sass/theme/layout/header/skins/base/light.scss";
@import "sass/theme/layout/header/skins/menu/light.scss";
@import "sass/theme/layout/brand/skins/dark.scss";
@import "sass/theme/layout/aside/skins/dark.scss";
@import "~@ng-select/ng-select/themes/material.theme.css";
// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
    min-width: 600px !important;
    width: 100% !important;
}

.position-static {
    position: static;
}

mat-footer-row {
    font-weight: bold;
}

.completed-payment-bg {
    background-color: rgb(235, 240, 229) !important;
}

.partial-payment-bg {
    background-color: rgb(248, 239, 225) !important;
}

.license-ending-bg {
    background-color: #ffebee !important;
}

.grayrow {
    background-color: #e4f0ec
}

.mat-form-field-appearance-legacy .mat-hint {
    color: #32536A!important;
    font-size: 10px!important;
}

.mat-input-element {
    height: 30px!important;
}

.mat-form-field-label {
    color: #162D3D!important;
    font-size: 17px!important;
    font-weight: 400!important;
}

// .mat-form-field-label-wrapper {
//     top: -20px!important;
// }
.popover {
    max-width: 350px!important;
    width: 350px!important;
}